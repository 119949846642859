import { scrollLocker } from './tools/scrollLocker';
import { initAccordions } from './components/initAccordions';
import { initShowMoreContent } from './components/initShowMoreContent';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileNavigation } from './components/initMobileNavigation';
import { initTestimonialsSlider } from './components/sliders/initTestimonialsSlider';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    scrollLocker();
    initMobileFeatureMenu();
    initMobileNavigation();
    initAccordions();
    initShowMoreContent();
    initScrollToAnchor();
    initForms();
    initStickyTableHeader();
    initRenderPricesPage();
    initTestimonialsSlider();
    initFunnelHash();
}
