import { isValidMail } from './helper-function/isValidMail';

export const initValidateStartFunnelForm = () => {
    // VARS
    const btnSubmits = document.querySelectorAll('[data-submit]');
    const agreeCheckboxes = document.querySelectorAll('[data-agree-checkbox]');
    const emailInputs = document.querySelectorAll('[data-validate-email]');
    const classHide = 'hide';
    let isValidForm = true;
    if (!btnSubmits.length || !agreeCheckboxes.length || !emailInputs.length) return;

    // LISTENERS
    [...btnSubmits].forEach(function (button) {
        button.addEventListener('click', handleOnSubmitForm, false);
    });

    [...agreeCheckboxes].forEach((agreeCheckbox) => {
        agreeCheckbox.addEventListener('change', handleOnChangeAgreeCheckbox, false);
    });

    [...emailInputs].forEach((emailInput) => {
        emailInput.addEventListener('focus', handleOnFocusEmailInput, false);
    });

    // HANDLERS
    function handleOnSubmitForm(event) {
        validateForm(this.closest('[data-form]'));

        if (!isValidForm) {
            event.preventDefault();
            isValidForm = true;
        }
    }

    function handleOnChangeAgreeCheckbox() {
        if (this.checked) {
            hideAgreeCheckboxError(this);
        }
    }

    function handleOnFocusEmailInput() {
        hideEmailInputError(this);
    }

    // FUNCTIONS
    function validateForm(form) {
        validateEmail(form);
        validateAgreeCheckbox(form);
    }

    function validateEmail(form) {
        const input = form.querySelector('[data-validate-email]');
        const error = form.querySelector('[data-error-email]');

        if (isValidMail(input.value.trim())) {
            hideErrors(error);
        } else {
            isValidForm = false;
            showErrors(error);
        }
    }

    function validateAgreeCheckbox(form) {
        const checkbox = form.querySelector('[data-agree-checkbox]');
        const error = form.querySelector('[data-error-agree]');

        if (checkbox.checked) {
            hideErrors(error);
        } else {
            isValidForm = false;
            showErrors(error);
        }
    }

    function hideAgreeCheckboxError(checkbox) {
        const form = checkbox.closest('[data-form]');
        const error = form.querySelector('[data-error-agree]');

        if (!error.classList.contains(classHide)) {
            hideErrors(error);
        }
    }

    function hideEmailInputError(input) {
        const form = input.closest('[data-form]');
        const error = form.querySelector('[data-error-email]');

        hideErrors(error);
    }

    function showErrors(errorElem) {
        errorElem.classList.remove(classHide);
    }

    function hideErrors(errorElem) {
        errorElem.classList.add(classHide);
    }
};
